import { Spiner } from "@components/loaders/spiner";
import { Order } from "@services/dtos/marketplace.dto";
import { MarketplaceService } from "@services/marketplace.service";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import { toRp } from "@utils/helper";
import { linkPage } from "@utils/router";
import moment from "moment";
import { useEffect, useState } from "react";
import { BiSolidShoppingBag } from "react-icons/bi";
import { FaChevronDown } from "react-icons/fa";
import { FcFinePrint } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

export const MarketplaceHistoryPage = () => {
  const navigate = useNavigate();
  const { setFooter, setHeader, setAppBar, setBottomNavigation } =
    useThemeStore((state) => state);
  const [range, setRange] = useState<"WEEK" | "MONTH">("WEEK");
  const [status, setStatus] = useState<
    | "ALL"
    | "KONFIRMASI"
    | "DIPROSES"
    | "DIKIRIM"
    | "DITERIMA PETUGAS"
    | "DITERIMA SANTRI"
    | "DIBATALKAN"
  >("ALL");

  const { data, isPending } = useQuery({
    queryKey: [
      MarketplaceService.queries.GET_MARKETPLACE_ORDERS,
      range,
      status,
    ],
    queryFn: () => MarketplaceService.historyOrders({ range, status }),
  });

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "History Pesanan",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "History Pesanan" });
    setBottomNavigation("marketplace");
  }, [setFooter, setHeader, setAppBar, setBottomNavigation]);

  const onOpenDetail = (order: Order) => {
    navigate(linkPage.MARKETPLACE_HISTORY_DETAIL, { state: order });
  };

  return (
    <div className="card card-style">
      <div className="content mb-0">
        <p className="mb-3">
          Data difilter dari{" "}
          {moment()
            .subtract(
              1,
              (range.toLowerCase() +
                "s") as moment.unitOfTime.DurationConstructor
            )
            .format("DD MMMM YYYY")}{" "}
          - {moment().format("DD MMMM YYYY")}
        </p>

        <div className="input-style has-borders input-style-always-active no-icon mb-4">
          <label htmlFor="form5" className="color-highlight font-500">
            Filter Range
          </label>
          <select
            onChange={(e) => setRange(e.target.value as "WEEK" | "MONTH")}
            defaultValue="WEEK"
            className="bg-theme"
          >
            <option value="default" disabled>
              Filter Range
            </option>
            <option value="WEEK">7 Hari Terakhir</option>
            <option value="MONTH">30 Hari Terakhir</option>
          </select>
          <span>
            <i>
              <FaChevronDown />
            </i>
          </span>
        </div>
        <div className="input-style has-borders input-style-always-active no-icon mb-4">
          <label htmlFor="form5" className="color-highlight font-500">
            Status Pesanan
          </label>
          <select
            onChange={(e) =>
              setStatus(
                e.target.value as
                  | "ALL"
                  | "KONFIRMASI"
                  | "DIPROSES"
                  | "DIKIRIM"
                  | "DITERIMA PETUGAS"
                  | "DITERIMA SANTRI"
                  | "DIBATALKAN"
              )
            }
            defaultValue="ALL"
            className="bg-theme"
          >
            <option value="default" disabled>
              Status Pesanan
            </option>
            <option value="ALL">Semua Status</option>
            <option value="KONFIRMASI">Konfirmasi</option>
            <option value="DIPROSES">Diproses</option>
            <option value="DIKIRIM">Dikirim</option>
            <option value="DITERIMA PETUGAS">Diterima Petugas</option>
            <option value="DITERIMA SANTRI">Diterima Santri</option>
            <option value="DIBATALKAN">Dibatalkan</option>
          </select>
          <span>
            <i>
              <FaChevronDown />
            </i>
          </span>
        </div>

        {isPending ? (
          <div className="d-flex p-3">
            <Spiner className="!tw-text-gray-200 tw-fill-gray-500 dark:tw-fill-white dark:!tw-text-gray-600 tw-w-8 tw-h-8 m-auto " />
          </div>
        ) : data?.data != null && data.data.length > 0 ? (
          <div className="list-group list-group-flush">
            {data.data.map((item, idx) => (
              <div
                key={idx}
                className="list-group-item tw-bg-inherit d-flex tw-px-0 tw-py-3 tw-border-[rgba(0,0,0,0.05)] dark:tw-border-[rgba(255,255,255,0.05)] tw-cursor-pointer"
                onClick={() => onOpenDetail(item)}
              >
                <div
                  className={`rounded-sm tw-w-10 tw-h-10 d-flex tw-flex-none my-auto bg-highlight`}
                >
                  <BiSolidShoppingBag className="m-auto" size={18} />
                </div>
                <div className="tw-grow mx-2 my-auto d-flex flex-column tw-w-[calc(100vw-200px)]">
                  <strong className="tw-font-normal tw-text-sm tw-leading-4 color-theme tw-truncate mb-1">
                    {item.productName ?? "-"}
                  </strong>
                  <strong className="tw-font-normal tw-text-xs tw-leading-4 tw-text-[#adb5bd] tw-truncate">
                    <span className="badge bg-blue-dark color-white font-10 tw-py-1 px-2 rounded-2">
                      {item.qty} Barang
                    </span>{" "}
                    | {moment(item.orderAt).format("HH:mm - DD MMMM YYYY")}
                  </strong>
                </div>
                <div className="my-auto tw-flex tw-flex-col">
                  <span
                    className={`badge ${
                      item.statusOrder === "KONFIRMASI"
                        ? "bg-gray-dark"
                        : item.statusOrder === "DIPROSES"
                        ? "bg-blue-dark"
                        : item.statusOrder === "DIKIRIM"
                        ? "bg-green-dark"
                        : item.statusOrder === "DITERIMA PETUGAS"
                        ? "bg-magenta-dark"
                        : item.statusOrder === "DITERIMA SANTRI"
                        ? "bg-green-dark"
                        : item.statusOrder === "DIBATALKAN"
                        ? "bg-red-dark"
                        : "-"
                    } color-white font-10 tw-py-1 px-2 rounded-2 mb-1`}
                  >
                    {item.statusOrder === "KONFIRMASI"
                      ? "Konfirmasi"
                      : item.statusOrder === "DIPROSES"
                      ? "Diproses"
                      : item.statusOrder === "DIKIRIM"
                      ? "Dikirim"
                      : item.statusOrder === "DITERIMA PETUGAS"
                      ? "Diterima Petugas"
                      : item.statusOrder === "DITERIMA SANTRI"
                      ? "Diterima Santri"
                      : item.statusOrder === "DIBATALKAN"
                      ? "Dibatalkan"
                      : "-"}
                  </span>
                  <span className="tw-font-bold tw-text-base tw-truncate">
                    {toRp(item.sellPrice * item.qty)}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="tw-p-8 text-center ">
            <FcFinePrint size={50} />
            <div>Data tidak ditemukan</div>
          </div>
        )}
      </div>
    </div>
  );
};
