import PreloadLogo from "@assets/images/preload-logo.png";
import { BaseLayout } from "@components/generals/base_layout";
import { Footer } from "@components/generals/footer";
import { Header } from "@components/generals/header";
import { FaExclamationTriangle } from "react-icons/fa";
import { Link } from "react-router-dom";

export const NotFoundPage = () => {
  return (
    <BaseLayout>
      <Header classOverlay="tw-h-[180px]">
        <div className="page-title">
          <div className="tw-pt-24 text-center">
            <img
              className="tw-w-32 tw-h-32 tw-rounded-sm mb-3"
              src={PreloadLogo}
              alt="Rounded avatar"
            />
            <h2>
              Pondok Pesantren <br /> Al-Ma'Tuq
            </h2>
          </div>
        </div>
      </Header>
      <div className="card card-style text-center">
        <div className="content py-5">
          <h1>
            <FaExclamationTriangle className="color-red-dark tw-text-8xl"/>
          </h1>
          <h1 className="tw-text-6xl pt-3 pb-2">404</h1>
          <h4 className="text-uppercase pb-3">Page not Found</h4>
          <p className="boxed-text-l">
            The page you're looking for cannot be found. How about trying the
            homepage?
          </p>
          <div className="text-center">
            <Link
              to="/"
              className="back-button btn btn-m btn-center-s bg-highlight rounded-sm font-700 text-uppercase"
            >
              Kembali
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </BaseLayout>
  );
};
