import { Spiner } from "@components/loaders/spiner";
import { VirtualAccountReport } from "@services/dtos/finance.dto";
import { FinanceService } from "@services/finance.service";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import { toRp } from "@utils/helper";
import { linkPage } from "@utils/router";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { FcFinePrint } from "react-icons/fc";
import { RiSecurePaymentLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";

export const VirtualAccountReportPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [range, setRange] = useState<"10" | "20" | "50">("10");
  const [status, setStatus] = useState<
    "ALL" | "PENDING" | "COMPLETED" | "EXPIRED" | "ONHOLD" | "REJECTED"
  >("ALL");
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  const { data, isPending } = useQuery({
    queryKey: [
      FinanceService.queries.GET_FINANCE_VIRTUAL_ACCOUNT_REPORT,
      range,
      status,
    ],
    queryFn: () => FinanceService.virtualAccountReport({ range, status }),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setFooter(false);
    setHeader({
      type: "small",
      title: "Laporan Transaksi",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ leading: "back", title: "Laporan Transaksi" });
  }, [setFooter, setHeader, setAppBar, location.pathname]);

  const onOpenDetail = (virtualAccountReport: VirtualAccountReport) => {
    navigate(linkPage.FINANCE_VIRTUAL_ACCOUNT_REPORT_DETAIL, {
      state: virtualAccountReport,
    });
  };

  return (
    <div className="card card-style">
      <div className="content mb-0">
        <p className="mb-3">
          Data difilter dari{" "}
          {moment()
            .subtract(
              1,
              (range.toLowerCase() +
                "s") as moment.unitOfTime.DurationConstructor
            )
            .format("DD MMMM YYYY")}{" "}
          - {moment().format("DD MMMM YYYY")}
        </p>

        <div className="input-style has-borders input-style-always-active no-icon mb-4">
          <label htmlFor="form5" className="color-highlight font-500">
            Filter Range
          </label>
          <select
            onChange={(e) => setRange(e.target.value as "10" | "20" | "50")}
            defaultValue="10"
            className="bg-theme"
          >
            <option value="default" disabled>
              Filter Range
            </option>
            <option value="10">10 Data Terakhir</option>
            <option value="20">20 Data Terakhir</option>
            <option value="50">50 Data Terakhir</option>
          </select>
          <span>
            <i>
              <FaChevronDown />
            </i>
          </span>
        </div>
        <div className="input-style has-borders input-style-always-active no-icon mb-4">
          <label htmlFor="form5" className="color-highlight font-500">
            Status Transaksi
          </label>
          <select
            onChange={(e) =>
              setStatus(
                e.target.value as
                  | "ALL"
                  | "PENDING"
                  | "COMPLETED"
                  | "EXPIRED"
                  | "ONHOLD"
                  | "REJECTED"
              )
            }
            defaultValue="ALL"
            className="bg-theme"
          >
            <option value="default" disabled>
              Status Transaksi
            </option>
            <option value="ALL">Semua Status</option>
            <option value="PENDING">Pending</option>
            <option value="COMPLETED">Selesai</option>
            <option value="EXPIRED">Kadaluarsa</option>
            <option value="ONHOLD">Dalam proses</option>
            <option value="REJECTED">Ditolak</option>
          </select>
          <span>
            <i>
              <FaChevronDown />
            </i>
          </span>
        </div>

        {isPending ? (
          <div className="d-flex p-3">
            <Spiner className="!tw-text-gray-200 tw-fill-gray-500 dark:tw-fill-white dark:!tw-text-gray-600 tw-w-8 tw-h-8 m-auto " />
          </div>
        ) : data?.data != null && data.data.length > 0 ? (
          <div className="list-group list-group-flush">
            {data.data.map((item, idx) => (
              <div
                key={idx}
                className="list-group-item tw-bg-inherit d-flex tw-px-0 tw-py-3 tw-border-[rgba(0,0,0,0.05)] dark:tw-border-[rgba(255,255,255,0.05)] tw-cursor-pointer"
                onClick={() => onOpenDetail(item)}
              >
                <div className="rounded-sm tw-w-8 tw-h-8 d-flex tw-flex-none my-auto bg-mint-dark">
                  <RiSecurePaymentLine className="m-auto" size={18} />
                </div>
                <div className="tw-grow mx-3 my-auto d-flex flex-column tw-w-[calc(100vw-140px)]">
                  <span className="tw-font-medium tw-text-sm dark:tw-text-white tw-truncate">
                    {item.vaTrxId}
                  </span>
                  <strong className="tw-font-medium tw-text-[11px] tw-leading-4 tw-text-[#adb5bd] tw-truncate">
                    {toRp(item.vaTrxAmount)}
                  </strong>
                </div>
                <div className="my-auto">
                  {item.vaStatus === "PENDING" ? (
                    <span className="badge bg-orange-dark color-white font-10 tw-py-1 px-2 rounded-2 tw-ml-2">
                      PENDING
                    </span>
                  ) : item.vaStatus === "ONHOLD" ? (
                    <span className="badge bg-blue-dark color-white font-10 tw-py-1 px-2 rounded-2 tw-ml-2">
                      ONHOLD
                    </span>
                  ) : item.vaStatus === "COMPLETED" ? (
                    <span className="badge bg-green-dark color-white font-10 tw-py-1 px-2 rounded-2 tw-ml-2">
                      SELESAI
                    </span>
                  ) : item.vaStatus === "REJECTED" ? (
                    <span className="badge bg-yellow-dark color-white font-10 tw-py-1 px-2 rounded-2 tw-ml-2">
                      DITOLAK
                    </span>
                  ) : (
                    <span className="badge bg-red-dark color-white font-10 tw-py-1 px-2 rounded-2 tw-ml-2">
                      KADALUARSA
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="tw-p-8 text-center ">
            <FcFinePrint size={50} />
            <div>Data tidak ditemukan</div>
          </div>
        )}
      </div>
    </div>
  );
};
