import { create } from "zustand";

interface SchoolStore {
  name?: string;
  moto?: string;
  updateInfo(data: { name?: string; moto?: string }): void;
  clearInfo(): void;
  firstLoad(): void;
}

export const useSchoolStore = create<SchoolStore>((set) => ({
  name: undefined,
  moto: undefined,

  updateInfo: (data) => {
    localStorage.setItem("school-name", data.name ?? "");
    localStorage.setItem("school-moto", data.moto ?? "");
    set({ ...data });
  },
  clearInfo: () => {
    set({ name: undefined, moto: undefined });
  },
  firstLoad: () => {
    set({
      name: localStorage.getItem("school-name") ?? undefined,
      moto: localStorage.getItem("school-moto") ?? undefined,
    });
  },
}));
