import { AppBar } from "@components/generals/app_bar";
import { BottomNavigation } from "@components/generals/bottom_navigation";
import { ComponentProps } from "@declarations/props";
import { useInboxStore } from "@stores/inbox.store";
import { useThemeStore } from "@stores/theme.store";

export const BaseLayout: ComponentProps = ({ children }) => {
  const { unreadInbox } = useInboxStore((state) => state);
  const { appBar, bottomNavigation } = useThemeStore((state) => state);
  return (
    <div className="tw-h-screen">
      <div id="page">
        {appBar.show && <AppBar />}
        <div className="page-content">{children}</div>
        {bottomNavigation !== 'hidden' && <BottomNavigation inboxCount={unreadInbox} type={bottomNavigation} />}
      </div>
    </div>
  );
};
