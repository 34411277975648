import { ActivityRequest } from "@components/resolvers/activity.resolvers";
import { BaseService } from "./base.service";
import { ActivityFormResponse, ActivityResponse } from "./dtos/activity.dto";
import { BaseResponse } from "./dtos/base.dto";
import axios from "axios";

interface ActivityServiceInterface {
  queries: {
    GET_ACTIVITY_DATA: string;
    GET_ACTIVITY_LIST_FORM: string;
  };
  listForm(): Promise<ActivityFormResponse>;
  data(limit: string): Promise<ActivityResponse>;
  request(payload: ActivityRequest): Promise<BaseResponse<boolean>>;
}
export const ActivityService: ActivityServiceInterface = {
  queries: {
    GET_ACTIVITY_DATA: "GET_ACTIVITY_DATA",
    GET_ACTIVITY_LIST_FORM: "GET_ACTIVITY_LIST_FORM",
  },
  listForm: async () => {
    try {
      const request = await BaseService.request().get<ActivityFormResponse>(
        "/activity/list-form"
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  data: async (limit: string) => {
    try {
      const request = await BaseService.request().get<ActivityResponse>(
        "/activity/data",
        { params: { limit } }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  request: async (payload: ActivityRequest) => {
    try {
      // const request = await BaseService.request().post<BaseResponse<boolean>>(
      //   "/activity/request",
      //   payload
      // );
      // return BaseService.response(request);
      const request = await axios.post(
        "https://almatuq.aplikasi-pesantren.id/api/kegiatan/request",
        payload,
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data,
      };
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
};
