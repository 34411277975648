import { Spiner } from "@components/loaders/spiner";
import {
  AuthResetPassword,
  authResetPasswordSchema,
} from "@components/resolvers/auth.resolvers";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthService } from "@services/auth.service";
import { useNotificationStore } from "@stores/notification.store";
import { useMutation } from "@tanstack/react-query";
import { linkPage } from "@utils/router";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaLock } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";

export const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pushNotification } = useNotificationStore((state) => state);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthResetPassword>({
    resolver: yupResolver<AuthResetPassword>(authResetPasswordSchema),
  });
  // useEffect(() => {
  //   console.log(errors);
  // }, [errors]);
  const { mutate, isPending } = useMutation({
    mutationFn: AuthService.resetPassword,
    onSuccess: (response) => {
      if (response.success) {
        pushNotification({
          type: "success",
          message: "Password berhasil diatur ulang",
        });
        navigate(linkPage.AUTH_LOGIN);
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  return (
    <div className="card card-style">
      <div className="content mt-2 mb-0">
        <h4 className="mt-3">Atur Ulang Kata Sandi</h4>
        <p>Masukkan kata sandi baru.</p>
        <form
          autoComplete="off"
          method="post"
          onSubmit={handleSubmit((data) => mutate(data))}
        >
          <input
            type="hidden"
            {...register("code", { value: searchParams.get("code") ?? "" })}
          />
          <div className="input-style no-borders has-icon validate-field mb-4">
            <i className="lh-1">
              <FaLock />
            </i>
            <input
              type="password"
              className="form-control validate-password"
              id="form3a"
              placeholder="Password Baru"
              {...register("password")}
            />
            <label htmlFor="form3a" className="color-blue-dark font-10 mt-1">
              Password Baru
            </label>
            {errors.password && (
              <span className="tw-font-medium tw-text-primary-700">
                {errors.password.message}
              </span>
            )}
          </div>

          <div className="input-style no-borders has-icon validate-field mb-4">
            <i className="lh-1">
              <FaLock />
            </i>
            <input
              type="password"
              className="form-control validate-password"
              id="form4a"
              placeholder="Konfirmasi Password"
              {...register("confirmPassword")}
            />
            <label htmlFor="form4a" className="color-blue-dark font-10 mt-1">
              Konfirmasi Password
            </label>
            {errors.confirmPassword && (
              <span className="tw-font-medium tw-text-primary-700">
                {errors.confirmPassword.message}
              </span>
            )}
          </div>

          <button
            type="submit"
            className="btn btn-m mt-4 mb-3 btn-full tw-w-full bg-green-dark rounded-sm text-uppercase font-900"
          >
            {isPending ? <Spiner /> : "Simpan Password"}
          </button>
        </form>

        <div className="divider mt-4 mb-3"></div>

        <p className="text-center tw-text-sm tw-font-light tw-text-gray-500 mb-4">
          Silahkan hubungi{" "}
          <span className="tw-font-medium tw-text-primary-700">Admin</span> jika
          terjadi masalah <br /> terhadap aplikasi
        </p>
      </div>
    </div>
  );
};
