import { ObjectSchema, object, string } from "yup";

// ------------- PEMIT REQUEST -------------
export type PermitRequest = {
  mode: string;
  // time: string;
  datePermit: string;
  dateBack: string;
  pickup: string;
  pickupStatus: string;
  notes: string;
};

export const permitRequestSchema: ObjectSchema<PermitRequest> = object({
  mode: string().required(),
  // time: string().required(),
  datePermit: string().required(),
  dateBack: string().required(),
  pickup: string().required(),
  pickupStatus: string().required(),
  notes: string().required(),
});
// ------------- PEMIT REQUEST -------------