import PreloadLogo from "@assets/images/preload-logo.png";
import { BaseLayout } from "@components/generals/base_layout";
import { Footer } from "@components/generals/footer";
import { Header } from "@components/generals/header";
import { SchoolService } from "@services/school.service";
import { useSchoolStore } from "@stores/school.store";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import { androidChangeThemeStatusBar } from "@utils/android";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export const AuthPage = () => {
  const { themeMode } = useThemeStore((state) => state);
  const { name, updateInfo } = useSchoolStore((state) => state);
  const { data: infoSchool } = useQuery({
    queryKey: [SchoolService.queries.GET_SCHOOL_INFO],
    queryFn: SchoolService.info,
  });

  useEffect(() => {
    if (infoSchool) {
      updateInfo({
        name: infoSchool?.data?.namasekolah,
        moto: infoSchool?.data?.moto,
      });
    }
  }, [infoSchool, updateInfo]);

  useEffect(() => {
    if (themeMode === "light") {
      androidChangeThemeStatusBar("#8CC152", "dark");
    }

    if (themeMode === "dark") {
      androidChangeThemeStatusBar("#47602B", "light");
    }
  }, [themeMode]);

  return (
    <BaseLayout>
      <Header classOverlay="tw-h-[180px]">
        <div className="page-title">
          <div className="tw-pt-24 text-center">
            <img
              className="tw-w-32 tw-h-32 tw-rounded-sm mb-3"
              src={PreloadLogo}
              alt="Rounded avatar"
            />
            <h2>{name ?? "Pondok Pesantren <br /> Al-Ma'Tuq"}</h2>
          </div>
        </div>
      </Header>
      <Outlet />
      <Footer />
    </BaseLayout>
  );
};
