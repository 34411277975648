import { useThemeStore } from "@stores/theme.store";
import { toRp } from "@utils/helper";
import { linkPage } from "@utils/router";
import moment from "moment";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const MarketplaceHistoryDetailPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setFooter, setHeader, setAppBar, setBottomNavigation } = useThemeStore((state) => state);

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Detail Pesanan",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Detail Pesanan", leading: "back" });
    setBottomNavigation("hidden");
  }, [setFooter, setHeader, setAppBar, setBottomNavigation]);

  return (
    <>
      <div className="card card-style">
        <div className="content d-flex flex-column mb-0 mt-3">
          <h5>Data Order</h5>
          <div className="divider mt-2 mb-3" />
          <div className="color-highlight">Waktu Pemesanan</div>
          <div className="color-theme mb-3">{state?.orderAt != null ? moment(state!.orderAt).format("dddd, DD MMMM YYYY | HH:mm") : "-"}</div>
          <div className="color-highlight">Status Pemesanan</div>
          <div className="color-theme mb-3">
            <span
              className={`badge ${
                state?.statusOrder === "KONFIRMASI"
                  ? "bg-gray-dark"
                  : state?.statusOrder === "DIPROSES"
                  ? "bg-blue-dark"
                  : state?.statusOrder === "DIKIRIM"
                  ? "bg-green-dark"
                  : state?.statusOrder === "DITERIMA PETUGAS"
                  ? "bg-magenta-dark"
                  : state?.statusOrder === "DITERIMA SANTRI"
                  ? "bg-green-dark"
                  : state?.statusOrder === "DIBATALKAN"
                  ? "bg-red-dark"
                  : "-"
              } color-white font-10 tw-py-1 px-2 rounded-2 mb-1`}
            >
              {state?.statusOrder === "KONFIRMASI"
                ? "Konfirmasi"
                : state?.statusOrder === "DIPROSES"
                ? "Diproses"
                : state?.statusOrder === "DIKIRIM"
                ? "Dikirim"
                : state?.statusOrder === "DITERIMA PETUGAS"
                ? "Diterima Petugas"
                : state?.statusOrder === "DITERIMA SANTRI"
                ? "Diterima Santri"
                : state?.statusOrder === "DIBATALKAN"
                ? "Dibatalkan"
                : "-"}
            </span>
          </div>
          <div className="color-highlight">Nama Barang</div>
          <div className="color-theme mb-3">{state?.productName}</div>
          <div className="color-highlight">Harga Barang</div>
          <div className="color-theme mb-3 tw-font-semibold tw-text-sm">{toRp(state?.sellPrice ?? 0)}</div>
          <div className="color-highlight">Jumlah Pesanan</div>
          <div className="color-theme mb-3">{state?.qty} Barang</div>
          <div className="color-highlight">Total Pembayaran</div>
          <div className="color-theme mb-3 tw-font-semibold tw-text-sm">{toRp(state?.total ?? 0)}</div>
          <div className="color-highlight">Metode Pembayaran</div>
          <div className="color-theme mb-3">
            <span className="badge bg-green-dark color-white font-10 tw-py-1 px-2 rounded-2 mb-1">{state?.paymentMetode ?? "-"}</span>
          </div>
          <div className="color-highlight">Catatan</div>
          <div className="color-theme mb-3">{state?.notesCustomer || state?.notesCustomer === "" ? "-" : state?.notesCustomer}</div>
          {state?.statusOrder === "DIBATALKAN" ? (
            <>
              <div className="color-highlight">Alasan Dibatalkan</div>
              <div className="color-theme mb-3">{state?.notesCustomer ?? "-"}</div>
            </>
          ) : null}
        </div>

        <div className="divider my-3"></div>

        <div className="mx-3">
          <button className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-3 tw-w-full tw-h-11 mb-4" onClick={() => navigate(linkPage.MARKETPLACE_PRODUCT, { state: { id: state?.productId }, replace: true })}>
            Pesan Lagi
          </button>
        </div>
      </div>
      <div className="card card-style">
        <div className="content d-flex flex-column mb-0 mt-3">
          <h5>Data Pemesan</h5>
          <div className="divider mt-2 mb-3" />
          <div className="color-highlight">Nama Pemesan</div>
          <div className="color-theme mb-3">{state?.studentName}</div>
          <div className="color-highlight">Kelas</div>
          <div className="color-theme mb-3">{state?.studentClass}</div>
          <div className="color-highlight">NIS</div>
          <div className="color-theme mb-3">{state?.nis}</div>
        </div>
      </div>
    </>
  );
};
