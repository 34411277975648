import { useThemeStore } from "@stores/theme.store";
import { toRp } from "@utils/helper";
import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const CheckingAccountDetailPage = () => {
  const { state } = useLocation();
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Detail Transaksi",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Detail Transaksi", leading: "back" });
  }, [setFooter, setHeader, setAppBar]);

  return (
    <div className="card card-style">
      <div className="content d-flex flex-column mb-0">
        <div className="color-highlight">Tanggal Transaksi</div>
        <div className="color-theme mb-3">
          {state?.updateAt != null
            ? moment(state!.updateAt).format("DD MMMM YYYY")
            : "-"}
        </div>
        <div className="color-highlight">ID Transaksi</div>
        <div className="color-theme mb-3">{state?.trxId}</div>
        <div className="color-highlight">Tipe Transaksi</div>
        <div className="color-theme mb-3">
          {state?.filter === "IN" ? "Uang Masuk" : "Uang Keluar"}
        </div>
        <div className="color-highlight">Nominal Transaksi</div>
        <div className="color-theme mb-3">{toRp(state?.nominal ?? 0)}</div>
        <div className="color-highlight">Catatan</div>
        <div className="color-theme mb-3">{state?.description ?? "-"}</div>
      </div>
    </div>
  );
};
