import { ActivityDataDetailPage } from "@pages/activity/activity_data_detail_page";
import { ActivityDataPage } from "@pages/activity/activity_data_page";
import { ActivityFormPage } from "@pages/activity/activity_form_page";
import { ActivityPage } from "@pages/activity/activity_page";
import { AuthPage } from "@pages/auth/auth_page";
import { ForgotPasswordPage } from "@pages/auth/forgot_password_page";
import { LoginPage } from "@pages/auth/login_page";
import { ResetPasswordPage } from "@pages/auth/reset_password_page";
import { UpdateAccountPage } from "@pages/auth/update_account_page";
import { BasePage } from "@pages/base_page";
import { NotFoundPage } from "@pages/errors/not_found_page";
import { BillDetailPage } from "@pages/finance/bill/bill_detail_page";
import { BillMonthlyPage } from "@pages/finance/bill/bill_monthly_page";
import { BillPage } from "@pages/finance/bill/bill_page";

import { OneBillPage } from "@pages/finance/bill/one_bill_page";
import { CheckingAccountDetailPage } from "@pages/finance/checking-account/checking_account_detail_page";
import { CheckingAccountPage } from "@pages/finance/checking-account/checking_account_page";
import { FinancePage } from "@pages/finance/finance_page";
import { PocketHistoryDetailPage } from "@pages/finance/pocket/pocket_history_detail_page";
import { PocketHistoryPage } from "@pages/finance/pocket/pocket_history_page";
import { PocketPage } from "@pages/finance/pocket/pocket_page";
import { VirtualAccountReportDetailPage } from "@pages/finance/virtual-account/virtual_accounts_report_detail_page";
import { VirtualAccountReportPage } from "@pages/finance/virtual-account/virtual_accounts_report_page";
import { VirtualAccountsRequestPage } from "@pages/finance/virtual-account/virtual_accounts_request_page";
import { DashboardPage } from "@pages/home/dashboard_page";
import { HistoryPage } from "@pages/home/history_page";
import { HomePage } from "@pages/home/home_page";
import { InboxDetailPage } from "@pages/home/inbox_detail_page";
import { InboxPage } from "@pages/home/Inbox_page";
import { OtherMenusPage } from "@pages/home/outher_menus_page";
import { ProfilePage } from "@pages/home/profile_page";
import { MarketplaceCheckoutPage } from "@pages/marketplace/marketplace_checkout_page";
import { MarketplaceHistoryDetailPage } from "@pages/marketplace/marketplace_history_detail_page";
import { MarketplaceHistoryPage } from "@pages/marketplace/marketplace_history_page";
import { MarketplacePage } from "@pages/marketplace/marketplace_page";
import { MarketplaceProductPage } from "@pages/marketplace/marketplace_product_page";
import { MarketplaceSettingPage } from "@pages/marketplace/marketplace_setting_page";
import { PermitDataDetailPage } from "@pages/permit/permit_data_detail_page";
import { PermitDataPage } from "@pages/permit/permit_data_page";
import { PermitFormPage } from "@pages/permit/permit_form_page";
import { PermitPage } from "@pages/permit/permit_page";
import { StudentAbsencesDetailPage } from "@pages/school/absences/student_absences_detail_page";
import { StudentAbsencesPage } from "@pages/school/absences/student_absences_page";
import { StudentAchievementDetailPage } from "@pages/school/achievement/student_achievement_detail_page";
import { StudentAchievementPage } from "@pages/school/achievement/student_achievement_page";
import { StudentBioPage } from "@pages/school/bio/student_bio_page";
import { StudentHealthDetailPage } from "@pages/school/health/student_health_detail_page";
import { StudentHealthPage } from "@pages/school/health/student_health_page";
import { StudentHealthRecordDetailPage } from "@pages/school/health/student_health_record_detail_page";
import { StudentHealthRecordPage } from "@pages/school/health/student_health_record_page";
import { NewsPage } from "@pages/school/news/news_page";
import { NewsPageDetail } from "@pages/school/news/news_page_detail";
import { SchoolPage } from "@pages/school/school_page";
import { StudentViolationsDetailPage } from "@pages/school/violations/student_violations_detail_page";
import { StudentViolationsPage } from "@pages/school/violations/student_violations_page";
import { SettingPage } from "@pages/setting/setting_page";
import { SettingResetPasswordPage } from "@pages/setting/setting_reset_password_page";
import { SettingUpdateAccountPage } from "@pages/setting/setting_update_account_page";
import { createBrowserRouter } from "react-router-dom";

export const linkPage = {
  // Auth
  AUTH_LOGIN: "/auth/login",
  AUTH_FORGOT_PASSWORD: "/auth/forgot-password",
  AUTH_RESET_PASSWORD: "/auth/reset-password",
  AUTH_SUCCESS_RESET_PASSWORD: "/auth/success-reset-password",
  AUTH_UPDATE_ACCOUNT: "/auth/forgot-password",

  // Home
  HOME_DASHBOARD: "/home/dashboard",
  HOME_INBOX: "/home/inbox",
  HOME_INBOX_DETAIL: "/home/inbox-detail",
  HOME_HISTORY: "/home/history",
  HOME_PROFILE: "/home/profile",
  HOME_OTHER_MENUS: "/home/other-menus",

  // Setting
  SETTING_UPDATE_ACCOUNT: "/setting/update-account",
  SETTING_RESET_PASSWORD: "/setting/reset-password",

  // Marketplace
  MARKETPLACE_PRODUCT: "/marketplace/product",
  MARKETPLACE_CHECKOUT: "/marketplace/checkout",
  MARKETPLACE_HISTORY: "/marketplace/history",
  MARKETPLACE_HISTORY_DETAIL: "/marketplace/history-detail",
  MARKETPLACE_SETTING: "/marketplace/setting",

  // School
  SCHOOL_STUDENT_BIO: "/school/student-bio",
  SCHOOL_STUDENT_HEALTH: "/school/student-health",
  SCHOOL_STUDENT_HEALTH_RECORD: "/school/student-health-record",
  SCHOOL_STUDENT_HEALTH_DETAIL: "/school/student-health-detail",
  SCHOOL_STUDENT_HEALTH_RECORD_DETAIL: "/school/student-health-record-detail",
  SCHOOL_STUDENT_VIOLATIONS: "/school/student-violations",
  SCHOOL_STUDENT_VIOLATIONS_DETAIL: "/school/student-violations-detail",
  SCHOOL_STUDENT_ACHIEVEMENT: "/school/student-achievement",
  SCHOOL_STUDENT_ACHIEVEMENT_DETAIL: "/school/student-achievement-detail",
  SCHOOL_STUDENT_ABSENCES: "/school/student-absences",
  SCHOOL_STUDENT_ABSENCES_DETAIL: "/school/student-absences-detail",
  SCHOOL_NEWS: "/school/news",
  SCHOOL_NEWS_DETAIL: "/school/news-detail",

  // Finance
  FINANCE_BILL: "/finance/bill",
  FINANCE_BILL_MONTHLY: "/finance/bill-monthly",
  FINANCE_ONE_BILL: "/finance/bill-non-routine",
  FINANCE_BILL_DETAIL: "/finance/bill-detail",
  FINANCE_CHECKING_ACCOUNT: "/finance/checking-account",
  FINANCE_CHECKING_ACCOUNT_DETAIL: "/finance/checking-account-detail",
  FINANCE_POCKET: "/finance/pocket",
  FINANCE_POCKET_HISTORY: "/finance/pocket/:pocket/history",
  FINANCE_POCKET_HISTORY_DETAIL: "/finance/pocket/history-detail",
  FINANCE_VIRTUAL_ACCOUNT_REPORT: "/finance/virtual-accounts-report",
  FINANCE_VIRTUAL_ACCOUNT_REPORT_DETAIL:
    "/finance/virtual-accounts-report-detail",
  FINANCE_VIRTUAL_ACCOUNT_REQUEST: "/finance/virtual-accounts-request",
  FINANCE_TOPUP_SAVING: "/finance/virtual-accounts-request",

  // Activity
  ACTIVITY_DATA: "/activity/data",
  ACTIVITY_DATA_DETAIL: "/activity/data-detail",
  ACTIVITY_FORM: "/activity/form",

  // Permit
  PERMIT_DATA: "/permit/data",
  PERMIT_DATA_DETAIL: "/permit/data-detail",
  PERMIT_FORM: "/permit/form",
};

export const publicRoute = [
  "/auth/login",
  "/auth/forgot-password",
  "/auth/reset-password",
  "/auth/success-reset-password",
  "/auth/update-account",
];

export const routerRegister = createBrowserRouter([
  {
    path: "/",
    element: <BasePage />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "auth",
        element: <AuthPage />,
        children: [
          {
            index: true,
            path: "login",
            element: <LoginPage />,
          },
          {
            path: "forgot-password",
            element: <ForgotPasswordPage />,
          },
          {
            path: "reset-password",
            element: <ResetPasswordPage />,
          },
          {
            path: "update-account",
            element: <UpdateAccountPage />,
          },
        ],
      },
      {
        path: "home/other-menus",
        element: <OtherMenusPage />,
      },
      {
        path: "home",
        element: <HomePage />,
        children: [
          {
            index: true,
            path: "dashboard",
            element: <DashboardPage />,
          },
          {
            path: "inbox",
            element: <InboxPage />,
          },
          {
            path: "inbox-detail",
            element: <InboxDetailPage />,
          },
          {
            path: "history",
            element: <HistoryPage />,
          },
          {
            path: "profile",
            element: <ProfilePage />,
          },
        ],
      },
      {
        path: "setting",
        element: <SettingPage />,
        children: [
          {
            index: true,
            path: "update-account",
            element: <SettingUpdateAccountPage />,
          },
          {
            path: "reset-password",
            element: <SettingResetPasswordPage />,
          },
        ],
      },
      {
        path: "school",
        element: <SchoolPage />,
        children: [
          {
            index: true,
            path: "student-bio",
            element: <StudentBioPage />,
          },
          {
            path: "student-achievement",
            element: <StudentAchievementPage />,
          },
          {
            path: "student-achievement-detail",
            element: <StudentAchievementDetailPage />,
          },
          {
            path: "student-violations",
            element: <StudentViolationsPage />,
          },
          {
            path: "student-violations-detail",
            element: <StudentViolationsDetailPage />,
          },
          {
            path: "student-absences",
            element: <StudentAbsencesPage />,
          },
          {
            path: "student-absences-detail",
            element: <StudentAbsencesDetailPage />,
          },
          {
            path: "student-health",
            element: <StudentHealthPage />,
          },
          {
            path: "student-health-record",
            element: <StudentHealthRecordPage />,
          },
          {
            path: "student-health-detail",
            element: <StudentHealthDetailPage />,
          },
          {
            path: "student-health-record-detail",
            element: <StudentHealthRecordDetailPage />,
          },
          {
            path: "news",
            element: <NewsPage />,
          },
          {
            path: "news-detail",
            element: <NewsPageDetail />,
          },
        ],
      },
      {
        path: "finance",
        element: <FinancePage />,
        children: [
          {
            index: true,
            path: "bill",
            element: <BillPage />,
          },
          {
            index: true,
            path: "bill-monthly",
            element: <BillMonthlyPage />,
          },
          {
            index: true,
            path: "bill-non-routine",
            element: <OneBillPage />,
          },
          {
            path: "bill-detail",
            element: <BillDetailPage />,
          },
          {
            path: "pocket",
            element: <PocketPage />,
          },
          {
            path: "pocket/:pocket/history",
            element: <PocketHistoryPage />,
          },
          {
            path: "pocket/history-detail",
            element: <PocketHistoryDetailPage />,
          },
          {
            path: "virtual-accounts-report",
            element: <VirtualAccountReportPage />,
          },
          {
            path: "virtual-accounts-request",
            element: <VirtualAccountsRequestPage />,
          },
          {
            path: "virtual-accounts-report-detail",
            element: <VirtualAccountReportDetailPage />,
          },
          {
            path: "checking-account",
            element: <CheckingAccountPage />,
          },
          {
            path: "checking-account-detail",
            element: <CheckingAccountDetailPage />,
          },
        ],
      },
      {
        path: "marketplace",
        element: <MarketplacePage />,
        children: [
          {
            index: true,
            path: "product",
            element: <MarketplaceProductPage />,
          },
          {
            path: "checkout",
            element: <MarketplaceCheckoutPage />,
          },
          {
            path: "history",
            element: <MarketplaceHistoryPage />,
          },
          {
            path: "history-detail",
            element: <MarketplaceHistoryDetailPage />,
          },
          {
            path: "setting",
            element: <MarketplaceSettingPage />,
          },
        ],
      },
      {
        path: "activity",
        element: <ActivityPage />,
        children: [
          {
            index: true,
            path: "data",
            element: <ActivityDataPage />,
          },
          {
            path: "data-detail",
            element: <ActivityDataDetailPage />,
          },
          {
            path: "form",
            element: <ActivityFormPage />,
          },
        ],
      },
      {
        path: "permit",
        element: <PermitPage />,
        children: [
          {
            index: true,
            path: "data",
            element: <PermitDataPage />,
          },
          {
            path: "data-detail",
            element: <PermitDataDetailPage />,
          },
          {
            path: "form",
            element: <PermitFormPage />,
          },
        ],
      },
    ],
  },
]);
