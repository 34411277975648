import Dexie, { Table } from 'dexie';

export interface MarketplaceCart {
  id?: number;
  check: boolean;
  name: string;
  picture: string;
  price: number;
  qty: number;
  message: string;
}

export class Database extends Dexie {
  carts!: Table<MarketplaceCart>; 

  constructor() {
    super('sipond');
    this.version(1).stores({
      carts: '++id, check, name, picture, price, qty, message'
    });
  }
}

export const db = new Database();