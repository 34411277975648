import { Application } from "@components/application";
import React from "react";
import ReactDOM from "react-dom/client";

import moment from "moment";
import "moment/locale/id";

import "@assets/scss/bootstrap.scss";
import "@assets/scss/style.scss";
import "@utils/event";
import "animate.css";

moment.locale("id");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>
);