import { useThemeStore } from "@stores/theme.store";
import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const NewsPageDetail = () => {
  const { state } = useLocation();
  const location = useLocation();
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  useEffect(() => {
    window.scrollTo(0, 0);
    setFooter(false);
    setHeader({
      type: "small",
      title: "Berita Terkini",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Berita Terkini", leading: "back" });
  }, [setFooter, setHeader, setAppBar, location.pathname]);

  return (
    <div className="card card-style">
      <div className="content d-flex flex-column mb-0">
        <h5 className="color-theme mb-3">{state.judul}</h5>
        <div className="card mb-1 rounded-m">
          <img
            className="rounded-s mb-2"
            src={state.gambar}
            alt=""
            style={{ maxHeight: "350px", objectFit: "cover" }}
          />
        </div>
        <small className="color-theme mb-3" style={{ fontSize: "10px" }}>
          {moment(state.tanggal ?? Date.now()).format("dddd, DD MMMM YYYY")}
        </small>
        <pre
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            width: "100%",
            boxSizing: "border-box",
          }}
          className="color-theme mb-3"
        >
          {state.pesan}
        </pre>
      </div>
    </div>
  );
};
