import { ObjectSchema, number, object, string } from "yup";

// ------------- POCKET TRANSFER -------------
export type PocketTransfer = {
  from: number;
  to: number;
  amount: number;
  pin: string;
};

export const pocketTransferSchema: ObjectSchema<PocketTransfer> = object({
  from: number().required(),
  to: number().required(),
  amount: number().required(),
  pin: string().max(6).min(6).required(),
});
// ------------- POCKET TRANSFER -------------
