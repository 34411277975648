import { AndroidDataEventMap } from "@declarations/event";
import { Inbox } from "@services/dtos/base.dto";
import { useThemeStore } from "@stores/theme.store";
import { androidListInbox, androidReadInbox } from "@utils/android";
import { linkPage } from "@utils/router";
import { useCallback, useEffect, useState } from "react";
import { FaAngleRight, FaBell, FaChevronDown } from "react-icons/fa";
import { FcFinePrint } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

export const InboxPage = () => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState<string>("10");
  const [inboxs, setInboxs] = useState<Array<Inbox>>([]);
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  const onListInboxListener = useCallback(
    (ev: MessageEvent<AndroidDataEventMap["on-list-inbox"]>) =>
      setInboxs(ev.data),
    []
  );

  useEffect(() => androidListInbox(limit), [limit]);

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Kotak Masuk",
      buttonBack: false,
      avatar: true,
    });
    setAppBar({ leading: "home", title: "Kotak Masuk" });

    window.addEventListener("on-list-inbox", onListInboxListener);

    return () => {
      window.removeEventListener("on-list-inbox", onListInboxListener);
    };
  }, [setFooter, setHeader, setAppBar, onListInboxListener]);

  const onOpenInbox = (inbox: Inbox) => {
    if (!inbox.isRead) androidReadInbox(inbox.id.toString());
    navigate(linkPage.HOME_INBOX_DETAIL, { state: inbox });
  };

  return (
    <div className="card card-style">
      <div className="content mb-0">
        <p className="mb-3">
          Data yang ditampilkan hanya {limit} data terakhir
        </p>

        <div className="input-style has-borders input-style-always-active no-icon mb-4">
          <label htmlFor="form5" className="color-highlight font-500">
            Filter Jumlah ditampilkan
          </label>
          <select
            defaultValue="10"
            onChange={(e) => setLimit(e.target.value)}
            className="bg-theme"
          >
            <option value="default" disabled>
              Filter Jumlah ditampilkan
            </option>
            <option value="10">10 Inbox Terakhir</option>
            <option value="25">25 Inbox Terakhir</option>
            <option value="50">50 Inbox Terakhir</option>
            <option value="100">100 Inbox Terakhir</option>
            <option value="all">Semuanya</option>
          </select>
          <span>
            <i>
              <FaChevronDown />
            </i>
          </span>
        </div>
        {inboxs.length < 1 ? (
          <div className="tw-p-8 text-center ">
            <FcFinePrint size={50} />
            <div>Data tidak ditemukan</div>
          </div>
        ) : (
          <div className="list-group list-group-flush">
            {inboxs.map((item, idx) => (
              <div
                key={idx}
                className="list-group-item tw-bg-inherit d-flex tw-px-0 tw-py-3 tw-border-[rgba(0,0,0,0.05)] dark:tw-border-[rgba(255,255,255,0.05)] tw-cursor-pointer"
                onClick={() => onOpenInbox(item)}
              >
                <div
                  className={`rounded-sm tw-w-8 tw-h-8 d-flex tw-flex-none my-auto ${
                    item.isRead ? "bg-gray-dark" : "bg-blue-dark"
                  }`}
                >
                  <FaBell className="m-auto" />
                </div>
                <div className="tw-grow mx-3 my-auto d-flex flex-column tw-w-[calc(100vw-140px)]">
                  <span className="tw-font-medium tw-text-sm dark:tw-text-white tw-truncate">
                    {item.title}
                  </span>
                  <strong className="tw-font-medium tw-text-[11px] tw-text-[#adb5bd] tw-truncate">
                    {item.message}
                  </strong>
                </div>
                <div className="my-auto">
                  <FaAngleRight
                    className="dark:tw-text-white tw-text-gray-500"
                    size={10}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
