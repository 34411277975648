import { useSchoolStore } from "@stores/school.store";

export const Footer = () => {
  const { name, moto } = useSchoolStore((state) => state);
  return (
    <>
      <div className="footer mt-5">
        <div className="footer card card-style mx-0 mb-0">
          <span className="footer-title !tw-text-xl pt-4 mb-3">
            {name ?? "PESANTREN AL - MA'TUQ"}
          </span>
          <p className="boxed-text-l mb-3">{moto ?? "Moto Pondok"}</p>
          <p className="footer-copyright mb-1">
            Copyright &copy; Sipond <span id="copyright-year">2023</span>. All
            Rights Reserved.
          </p>
        </div>
        <div
          className="footer-card card shape-rounded bg-20"
          style={{ height: "230px" }}
        >
          <div className="card-overlay bg-highlight opacity-90"></div>
        </div>
      </div>
    </>
  );
};
