import { BaseLayout } from "@components/generals/base_layout";
import { Header } from "@components/generals/header";
import { useThemeStore } from "@stores/theme.store";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export const SchoolPage = () => {
  const { setBottomNavigation, setAppBar } = useThemeStore((state) => state);
  useEffect(() => {
    setBottomNavigation("hidden");
    setAppBar({ show: true, leading: "back", actions: [] });
  }, [setBottomNavigation, setAppBar]);

  return (
    <BaseLayout>
      <Header />
      <Outlet />
    </BaseLayout>
  );
};
