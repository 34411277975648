import { FilterProduct } from "@components/resolvers/marketplace.resolvers";
import { BaseService } from "./base.service";
import { BaseResponse } from "./dtos/base.dto";
import { CheckoutRequest, MerchantResponse, OrderResponse, ProductResponse } from "./dtos/marketplace.dto";

interface MarketplaceServiceInterface {
  queries: {
    GET_MARKETPLACE_PRODUCTS: string;
    GET_MARKETPLACE_MERCHANT: string;
    GET_MARKETPLACE_ORDERS: string;
  };
  products(params: FilterProduct): Promise<ProductResponse>;
  merchant(): Promise<MerchantResponse>;
  historyOrders(payload: { range: "WEEK" | "MONTH"; status: "ALL" | "KONFIRMASI" | "DIPROSES" | "DIKIRIM" | "DITERIMA PETUGAS" | "DITERIMA SANTRI" | "DIBATALKAN" }): Promise<OrderResponse>;
  checkout(payload: CheckoutRequest): Promise<BaseResponse<number[]>>;
  checkPin(): Promise<BaseResponse<boolean>>;
  createPin(pin: string): Promise<BaseResponse<boolean>>;
}
export const MarketplaceService: MarketplaceServiceInterface = {
  queries: {
    GET_MARKETPLACE_PRODUCTS: "GET_MARKETPLACE_PRODUCTS",
    GET_MARKETPLACE_MERCHANT: "GET_MARKETPLACE_MERCHANT",
    GET_MARKETPLACE_ORDERS: "GET_MARKETPLACE_ORDERS",
  },
  products: async (params: { search: string; category: string; merchant: string }) => {
    try {
      const request = await BaseService.request().get<ProductResponse>("/marketplace/products", { params });
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  merchant: async () => {
    try {
      const request = await BaseService.request().get<MerchantResponse>("/marketplace/merchants");
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  checkPin: async () => {
    try {
      const request = await BaseService.request().get<BaseResponse<boolean>>("/marketplace/check-pin");
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  createPin: async (pin: string) => {
    try {
      const request = await BaseService.request().get<BaseResponse<boolean>>(`/marketplace/create-pin/${pin}`);
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  checkout: async (payload: CheckoutRequest) => {
    try {
      const request = await BaseService.request().post<BaseResponse<number[]>>("/marketplace/checkout", payload);
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  historyOrders: async (payload: { range: "WEEK" | "MONTH"; status: "ALL" | "KONFIRMASI" | "DIPROSES" | "DIKIRIM" | "DITERIMA PETUGAS" | "DITERIMA SANTRI" | "DIBATALKAN" }) => {
    try {
      const request = await BaseService.request().get<OrderResponse>(`/marketplace/history-orders`, { params: payload });
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
};
