import { Spiner } from "@components/loaders/spiner";
import {
  AuthUpdateAccount,
  authUpdateAccountSchema,
} from "@components/resolvers/auth.resolvers";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthService } from "@services/auth.service";
import { useNotificationStore } from "@stores/notification.store";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { BsInfoCircleFill } from "react-icons/bs";
import { FaAt, FaCheck, FaLock, FaPhone, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const UpdateAccountPage = () => {
  const navigate = useNavigate();
  const { pushNotification } = useNotificationStore((state) => state);
  const { register, handleSubmit } = useForm<AuthUpdateAccount>({
    resolver: yupResolver<AuthUpdateAccount>(authUpdateAccountSchema),
  });

  useEffect(() => {
    initValidate();
  }, []);

  const { mutate, isPending } = useMutation({
    mutationFn: AuthService.updateAccount,
    onSuccess: (response) => {
      if (response.success) {
        navigate(response.data!.redirect);
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  return (
    <>
      <div className="alert me-3 ms-3 rounded-s bg-blue-dark" role="alert">
        <span className="alert-icon">
          <i className="font-20">
            <BsInfoCircleFill />
          </i>
        </span>
        <h4 className="color-white">Ahlaan Wa Sahlaan</h4>
        <p className="!tw-text-white !tw-ml-[46px] tw-mt-2 tw-mb-0">
          Ini pertama kalinya Bapak/Ibu wali santri login aplikasi ini. <br />{" "}
          Silahkan untuk melengkapi data terlebih dahulu.
        </p>
      </div>
      <div className="card card-style">
        <div className="content mt-2 mb-0">
          <form
            autoComplete="off"
            method="post"
            onSubmit={handleSubmit((data) => mutate(data))}
          >
            <div className="input-style no-borders has-icon validate-field mb-4">
              <i className="lh-1">
                <FaAt />
              </i>
              <input
                type="email"
                className="form-control"
                placeholder="Alamat Email"
                {...register("email")}
              />
              <label htmlFor="form1a" className="color-blue-dark font-10 mt-1">
                Alamat Email
              </label>
              <i className="disabled invalid color-red-dark">
                <FaTimes />
              </i>
              <i className="disabled valid color-green-dark">
                <FaCheck />
              </i>
              <em>(*wajib)</em>
            </div>

            <div className="input-style no-borders has-icon validate-field mb-4">
              <i className="lh-1">
                <FaPhone />
              </i>
              <input
                type="number"
                className="form-control"
                placeholder="Nomor Handphone"
                {...register("nohp")}
              />
              <label htmlFor="form1a" className="color-blue-dark font-10 mt-1">
                Nomor Handphone
              </label>
              <i className="disabled invalid color-red-dark">
                <FaTimes />
              </i>
              <i className="disabled valid color-green-dark">
                <FaCheck />
              </i>
              <em>(*wajib)</em>
            </div>

            <div className="input-style no-borders has-icon validate-field mb-4">
              <i className="lh-1">
                <FaLock />
              </i>
              <input
                type="password"
                className="form-control validate-password"
                placeholder="Password Baru"
                {...register("password")}
              />
              <label htmlFor="form3a" className="color-blue-dark font-10 mt-1">
                Password Baru
              </label>
              <i className="disabled invalid color-red-dark">
                <FaTimes />
              </i>
              <i className="disabled valid color-green-dark">
                <FaCheck />
              </i>
              <em>(*wajib)</em>
            </div>

            <button
              type="submit"
              className="btn btn-m mt-4 mb-3 btn-full tw-w-full bg-green-dark rounded-sm text-uppercase font-900"
            >
              {isPending ? <Spiner /> : "Update Akun"}
            </button>
          </form>

          <div className="divider mt-4 mb-3"></div>

          <p className="text-center tw-text-sm tw-font-light tw-text-gray-500 mb-4">
            Silahkan hubungi{" "}
            <span className="tw-font-medium tw-text-primary-700">Admin</span>{" "}
            jika terjadi masalah <br /> terhadap aplikasi
          </p>
        </div>
      </div>
    </>
  );
};
