import { Notification } from "@components/generals/notification";
import { AndroidDataEventMap } from "@declarations/event";
import { publicRoute } from "@utils/router";
import Cookies from "js-cookie";
import { useCallback, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const BasePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const onNotificationClick = useCallback(
    (ev: MessageEvent<AndroidDataEventMap["on-notification-click"]>) => {
      const payload = JSON.parse(ev.data);
      navigate(payload["link"], { state: payload });
    },
    [navigate]
  );

  useEffect(() => {
    window.addEventListener("on-notification-click", onNotificationClick);
    return () => {
      window.removeEventListener("on-notification-click", onNotificationClick);
    };
  }, [onNotificationClick]);

  useEffect(() => {
    const token = Cookies.get("token");
    const redirect = Cookies.get("redirect");
    const isLoggedIn = token && redirect === "/home/dashboard" ? true : false;

    if (
      (publicRoute.includes(location.pathname) || location.pathname === "/") &&
      isLoggedIn
    ) {
      return navigate("/home/dashboard", { replace: true });
    }

    if (
      (!publicRoute.includes(location.pathname) || location.pathname === "/") &&
      !isLoggedIn
    ) {
      return navigate("/auth/login", { replace: true });
    }
  }, [navigate, location]);

  return (
    <>
      <Notification />
      <Outlet />
    </>
  );
};
