import axios from "axios";
import { BaseService } from "./base.service";
import {
  BillsAndBalancesResponse,
  MadingResponse,
  SlidersResponse,
} from "./dtos/home.dto";
import { BaseResponseExternal } from "./dtos/base.dto";

interface HomeServiceInterface {
  queries: {
    GET_SLIDER: string;
    GET_BILLS_AND_BALANCES: string;
    GET_MADING: string;
  };
  sliders(): Promise<SlidersResponse>;
  billsAndBalances(payload: { nis: string }): Promise<BillsAndBalancesResponse>;
  mading(kategori: string, limit: string): Promise<MadingResponse>;
}
export const HomeService: HomeServiceInterface = {
  queries: {
    GET_SLIDER: "GET_SLIDER",
    GET_BILLS_AND_BALANCES: "GET_BILLS_AND_BALANCES",
    GET_MADING: "GET_MADING",
  },

  sliders: async () => {
    try {
      const request = await BaseService.request().get<SlidersResponse>(
        "/home/sliders"
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  billsAndBalances: async (payload: { nis: string }) => {
    try {
      // const request = await BaseService.request().get<BillsAndBalancesResponse>(
      //   "/home/bills-and-balances"
      // );
      // localStorage.setItem("nis", request.data.data?.nis ?? "");
      // return BaseService.response(request);
      const request = await axios.get<BillsAndBalancesResponse>(
        `https://almatuq.aplikasi-pesantren.id/api/tagihan/bill-and-balance`,
        {
          params: payload,
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.success,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },

  mading: async (kategori: string, limit: string) => {
    try {
      const request = await axios.get<BaseResponseExternal>(
        `https://almatuq.aplikasi-pesantren.id/api/admin/mading-slider/${kategori}/${limit}`,
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
};
