import { useThemeStore } from "@stores/theme.store";
import { linkPage } from "@utils/router";
import { useEffect, useState } from "react";
import { FaArrowLeft, FaHome } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const AppBar = () => {
  const { appBar } = useThemeStore((state) => state);
  const [show, setShow] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleScroll = () => {
    const position = window.scrollY;
    setShow(position > 35);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLeading = () => {
    if (appBar.leading === "back") {
      navigate(-1);
    } else {
      navigate(linkPage.HOME_DASHBOARD, { replace: true });
    }
  };

  return (
    <div
      className={`header header-fixed header-auto-show header-logo-app ${
        (show || appBar.fixed) && "header-active"
      }`}
    >
      <span className="header-title">{appBar.title}</span>
      <span className="header-icon header-icon-1" onClick={handleLeading}>
        {appBar.leading === "home" ? (
          <FaHome size={16} />
        ) : (
          <FaArrowLeft size={16} />
        )}
      </span>
      {(appBar.actions ?? []).map((action) => action)}
    </div>
  );
};
