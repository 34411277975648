import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import CryptoJS from "crypto-js";
import { camelizeKeys, decamelizeKeys } from "humps";
import Cookies from "js-cookie";
import momentTimezone from "moment-timezone";
import { BaseResponse } from "./dtos/base.dto";

export const timeout = 30000;
export const appKey = process.env.REACT_APP_KEY;
export const baseURL = process.env.REACT_APP_BASE_URL;
export const defaultResponseError: BaseResponse = {
  success: false,
  errorCode: "E_INTERNAL_SERVER_ERROR",
  message: "Internal Server Error!",
  data: null,
};
export const defaultResponseSuccess: BaseResponse = {
  success: true,
  message: "Successfully!",
  data: null,
};
export const BaseService = {
  request(token?: string): AxiosInstance {
    const tokenLocal = Cookies.get("token");
    const authorization = tokenLocal || token || "";
    const datetime = momentTimezone
      .tz("Asia/Jakarta")
      .format("YYYY-MM-DD HH:mm");
    const signature = CryptoJS.SHA512(appKey + datetime).toString(
      CryptoJS.enc.Hex
    );

    const instance = axios.create({
      baseURL,
      timeout,
      headers: {
        Authorization: authorization,
        Signature: signature,
      },
    });

    instance.interceptors.response.use((response: AxiosResponse) => {
      if (
        response.data &&
        (response.headers["content-type"] === "application/json" ||
          response.headers["content-type"] ===
            "application/json; charset=utf-8")
      ) {
        response.data = camelizeKeys(response.data);
      }

      return response;
    });

    instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      const newConfig = { ...config };

      if (newConfig.headers["Content-Type"] === "multipart/form-data")
        return newConfig;

      if (config.params) {
        newConfig.params = decamelizeKeys(config.params);
      }

      if (config.data) {
        newConfig.data = decamelizeKeys(config.data);
      }

      return newConfig;
    });

    return instance;
  },
  response<T extends BaseResponse>(
    data: unknown,
    isError: boolean = false
  ): BaseResponse {
    if (isError) {
      if (data instanceof AxiosError) {
        const result = (data as AxiosError<T>).response?.data;
        return result ?? defaultResponseError;
      } else {
        return defaultResponseError;
      }
    } else {
      const result = (data as AxiosResponse<T>)?.data;
      return result ?? defaultResponseSuccess;
    }
  },
};
