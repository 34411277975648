import { useThemeStore } from "@stores/theme.store";
import { toRp } from "@utils/helper";
import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ActivityDataDetailPage = () => {
  const { state } = useLocation();
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Detail Kegiatan",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Detail Kegiatan", leading: "back" });
  }, [setFooter, setHeader, setAppBar]);

  return (
    <div className="card card-style">
      <div className="content d-flex flex-column mb-0">
        <div className="color-highlight">Nama Kegiatan</div>
        <div className="color-theme mb-3">{state?.activity ?? "-"}</div>
        <div className="color-highlight">Lokasi Kegiatan</div>
        <div className="color-theme mb-3">{state?.location ?? "-"}</div>
        <div className="color-highlight">Tanggal Pelaksanaan</div>
        <div className="color-theme mb-3">
          {moment(state?.date ?? Date.now()).format(
            "dddd, DD MMMM YYYY | HH:mm"
          )}
        </div>
        <div className="color-highlight">Biaya</div>
        <div className="color-theme mb-3">{toRp(state?.cost)}</div>
        <div className="color-highlight">Metode Pembayaran</div>
        <div className="color-theme mb-3">
          <span className="badge bg-green-dark color-white font-10 tw-py-1 px-2 rounded-2 mb-1">
            {state?.paymentMethod ?? "-"}
          </span>
        </div>
        <div className="color-highlight">Penjemput</div>
        <div className="color-theme mb-3">{state?.pickup ?? "-"}</div>
        <div className="color-highlight">Catatan</div>
        <div className="color-theme mb-3">{state?.notes ?? "-"}</div>
      </div>
    </div>
  );
};
