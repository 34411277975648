import { PopupSuccess } from "@components/generals/popup";
import { Spiner } from "@components/loaders/spiner";
import {
  SettingUpdateLimit,
  settingUpdateLimitSchema,
} from "@components/resolvers/setting.resolvers";
import { yupResolver } from "@hookform/resolvers/yup";
import { AccountService } from "@services/account.service";
import { SchoolService } from "@services/school.service";
import { useNotificationStore } from "@stores/notification.store";
import { useThemeStore } from "@stores/theme.store";
import { useMutation, useQuery } from "@tanstack/react-query";
// import { toRp } from "@utils/helper";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const MarketplaceSettingPage = () => {
  const navigate = useNavigate();

  const [popup, setPopup] = useState<boolean>(false);
  const { pushNotification } = useNotificationStore((state) => state);
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);
  const {
    register,
    handleSubmit,
    reset,
    // formState: { errors },
  } = useForm<SettingUpdateLimit>({
    resolver: yupResolver<SettingUpdateLimit>(settingUpdateLimitSchema),
  });

  const { data: dataNis } = useQuery({
    queryKey: [SchoolService.queries.GET_SCHOOL_STUDENT_BIO],
    queryFn: SchoolService.studentBio,
  });
  const nis = dataNis?.data?.nis ?? "";
  // const nis = localStorage.getItem("nis") ?? "";

  const { data: limit } = useQuery({
    queryKey: [AccountService.queries.GET_SETTING_LIMIT, nis],
    queryFn: async () => AccountService.settingLimit(nis),
  });

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Setting Belanja",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Setting Belanja" });
    reset({
      limit_belanja: limit?.data?.limit_belanja,
      limitedc: limit?.data?.limitedc,
    });
  }, [setFooter, setHeader, setAppBar, reset, limit]);

  const { mutate, isPending } = useMutation({
    mutationFn: AccountService.updateSettingLimit,
    onSuccess: (response) => {
      if (response.success) {
        setPopup(true);
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  return (
    <>
      <div className="card card-style">
        <div className="content mt-4">
          <form
            autoComplete="off"
            method="post"
            onSubmit={handleSubmit((data) =>
              mutate({
                ...data,
                limit_belanja: data.limit_belanja?.replace(",", "") ?? "0",
                limitedc: data.limitedc?.replace(",", "") ?? "0",
              })
            )}
          >
            <input
              type="hidden"
              className="form-control"
              {...register("nis", { value: nis })}
            />
            <div className="input-style input-style-always-active has-borders  validate-field mb-4">
              <input
                type="text"
                className="form-control"
                inputMode="numeric"
                {...register("limit_belanja", {
                  value: parseInt(
                    limit?.data?.limit_belanja ?? "0"
                  ).toLocaleString("en-US"),
                })}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  const formattedValue =
                    value === ""
                      ? "0"
                      : parseInt(value).toLocaleString("en-US");
                  e.target.value = formattedValue;
                }}
              />
              <label className="color-highlight text-uppercase font-700 font-10">
                Limit Belanja Al-Barokah
              </label>
              <em>Rupiah</em>
            </div>
            <div className="tw-leading-tight tw-text-xs tw-italic mb-5">
              Limit Ini Akan membatasi Santri untuk Berbelanja di Al-barokah{" "}
              <b>Via Admin di Pondok</b>. Adapun Belanja Oleh Orangtua Tidak
              Dibatasi
            </div>

            <div className="input-style input-style-always-active has-borders  validate-field mb-4">
              <input
                type="text"
                className="form-control"
                inputMode="numeric"
                {...register("limitedc", {
                  value: parseInt(limit?.data?.limitedc ?? "0").toLocaleString(
                    "en-US"
                  ),
                })}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  const formattedValue =
                    value === ""
                      ? "0"
                      : parseInt(value).toLocaleString("en-US");
                  e.target.value = formattedValue;
                }}
              />
              <label className="color-highlight text-uppercase font-700 font-10">
                Limit Pengambilan Uang Saku
              </label>
              <em>Rupiah</em>
            </div>
            <div className="tw-leading-tight tw-text-xs tw-italic">
              Limit Ini Akan membatasi Santri untuk <b>Pengambilan Uang Saku</b>
              .
            </div>

            <button
              type="submit"
              className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-4 tw-w-full tw-h-11"
            >
              {isPending ? <Spiner /> : "Simpan Limit"}
            </button>
          </form>
        </div>
      </div>
      <PopupSuccess
        show={popup}
        title="Berhasil!"
        message="Setting Limit berhasil disimpan"
        textButton="Kembali"
        onConfirm={() => navigate(-1)}
      />
    </>
  );
};
