import { PublishEvent } from "@declarations/event";

const publishEvent: PublishEvent = (eventName, data) => {
  const event = new MessageEvent(eventName, { data: data });
  window.dispatchEvent(event);
};

export { publishEvent };

if (typeof window !== "undefined") {
  if (typeof window.publishEvent === "undefined") {
    window.publishEvent = publishEvent;
  }
}
