import { useNotificationStore } from "@stores/notification.store";
import {
  BsExclamationTriangleFill,
  BsFillCheckCircleFill,
  BsFillXCircleFill,
  BsInfoCircleFill,
} from "react-icons/bs";

const typeColors = {
  warning: "yellow",
  success: "green",
  info: "blue",
  error: "red",
};

const typeIcons = {
  warning: <BsExclamationTriangleFill />,
  success: <BsFillCheckCircleFill />,
  info: <BsInfoCircleFill />,
  error: <BsFillXCircleFill />,
};

export const Notification = () => {
  const { notifications, removehNotification } = useNotificationStore(
    (state) => state
  );
  return (
    <div className="tw-fixed tw-top-4 tw-left-4 tw-right-4" style={{zIndex: 9999}}>
      {notifications.map((item, idx) => (
        <div
          key={idx}
          className={`ms-3 me-3 mb-3 alert alert-small rounded-s shadow-xl animate__animated animate__bounceIn bg-${
            typeColors[item.type]
          }-dark`}
          role="alert"
        >
          <span className="tw-rounded-l-lg">
            <i className="font-20">{typeIcons[item.type]}</i>
          </span>
          {item.title ? <h4 className="color-white">{item.title}</h4> : null}
          <div className="tw-ml-11 tw-mr-9">{item.message}</div>
          <button
            type="button"
            className="close color-white opacity-60 font-16"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => removehNotification(item.id)}
          >
            ×
          </button>
        </div>
      ))}
    </div>
  );
};
