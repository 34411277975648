export const toRp = (param: number) => {
  let currency = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });
  return currency
    .format(param)
    .replace(/^(\D+)/, "$1 ")
    .replace(/\s+/, " ");
};

export const delay = (delayInms: number) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};

export const titleize = (text: string) => {
  return text.toLowerCase().replaceAll(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
};