import { create } from "zustand";

type NotificationDataType = {
  id?: string;
  title?: string;
  message: string;
  type: "warning" | "success" | "info" | "error";
  delayed?: "fixed" | number;
};

interface NotificationStore {
  notifications: NotificationDataType[];
  pushNotification(data: NotificationDataType): void;
  removehNotification(id?: string): void;
}

export const useNotificationStore = create<NotificationStore>((set) => ({
  notifications: [],
  pushNotification: (data: NotificationDataType) => {
    data.id = Date.now() + (Math.random() + 1).toString(36).substring(2);
    set((state) => {
      let notifications = state.notifications;
      notifications.sort();
      notifications.reverse();
      return { notifications: notifications.concat(data) };
    });
    if (data.delayed === "fixed") return;
    setTimeout(() => {
      set((state) => ({
        notifications: state.notifications.filter((item) => item.id !== data.id),
      }));
    }, data.delayed ?? 3000);
  },
  removehNotification: (id?: string) => {
    set((state) => ({
      notifications: state.notifications.filter((item) => item.id !== id),
    }));
  },
}));
