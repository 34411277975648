import { BaseLayout } from "@components/generals/base_layout";
import { Header } from "@components/generals/header";
import { useThemeStore } from "@stores/theme.store";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export const MarketplacePage = () => {
  const { setBottomNavigation } = useThemeStore((state) => state);
  useEffect(() => {
    setBottomNavigation("marketplace");
  }, [setBottomNavigation]);

  return (
    <BaseLayout>
      <Header height={120}/>
      <Outlet />
    </BaseLayout>
  );
};
