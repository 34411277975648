import {
  SettingResetPassword,
  SettingUpdateAccount,
  SettingUpdateLimit,
} from "@components/resolvers/setting.resolvers";
import { BaseService } from "./base.service";
import {
  AccountProfileResponse,
  SettingLimitResponse,
  StudentsResponse,
} from "./dtos/account.dto";
import { BaseResponse } from "./dtos/base.dto";
import axios from "axios";

interface AccountServiceInterface {
  queries: {
    GET_ACCOUNT_PROFILE: string;
    GET_ACCOUNT_STUDENTS: string;
    GET_SETTING_LIMIT: string;
  };
  profile(): Promise<AccountProfileResponse>;
  students(): Promise<StudentsResponse>;
  changeStudent(nis: string): Promise<BaseResponse<boolean>>;
  updateTokenFirebase(token: string): Promise<BaseResponse<boolean>>;
  resetPassword(payload: SettingResetPassword): Promise<BaseResponse<boolean>>;
  updateAccount(payload: SettingUpdateAccount): Promise<BaseResponse<boolean>>;
  settingLimit(nis: string): Promise<SettingLimitResponse>;
  updateSettingLimit(
    payload: SettingUpdateLimit
  ): Promise<BaseResponse<boolean>>;
}
export const AccountService: AccountServiceInterface = {
  queries: {
    GET_ACCOUNT_PROFILE: "GET_ACCOUNT_PROFILE",
    GET_ACCOUNT_STUDENTS: "GET_ACCOUNT_STUDENTS",
    GET_SETTING_LIMIT: "GET_SETTING_LIMIT",
  },
  profile: async () => {
    try {
      const request = await BaseService.request().get<AccountProfileResponse>(
        "/account/profile"
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  students: async () => {
    try {
      const request = await BaseService.request().get<StudentsResponse>(
        "/account/students"
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  changeStudent: async (nis: string) => {
    try {
      const request = await BaseService.request().get<BaseResponse<boolean>>(
        "/account/change-student/" + nis
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  resetPassword: async (payload) => {
    try {
      const request = await BaseService.request().post<BaseResponse<boolean>>(
        "/account/reset-password",
        { password: payload.password_new }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  updateAccount: async (payload) => {
    try {
      const request = await BaseService.request().post<BaseResponse<boolean>>(
        "/account/edit-account",
        payload
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  updateTokenFirebase: async (token: string) => {
    try {
      const request = await BaseService.request().post<BaseResponse<boolean>>(
        "/account/update-token-firebase",
        { token }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  settingLimit: async (nis: string) => {
    try {
      const request = await axios.get(
        `https://almatuq.aplikasi-pesantren.id/api/keuangan/limit/${nis}`,
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data,
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.message,
        data: false,
      };
    }
  },

  updateSettingLimit: async (payload) => {
    try {
      const request = await axios.post(
        "https://almatuq.aplikasi-pesantren.id/api/keuangan/update-limit",
        payload,
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data,
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.message,
        data: false, // Return a boolean value for the data property
      };
    }
  },
};
