import { Spiner } from "@components/loaders/spiner";
import {
  AuthLogin,
  authLoginSchema,
} from "@components/resolvers/auth.resolvers";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthService } from "@services/auth.service";
import { useNotificationStore } from "@stores/notification.store";
import { useMutation } from "@tanstack/react-query";
import { linkPage } from "@utils/router";
import { useForm } from "react-hook-form";
import { FaCheck, FaLock, FaTimes, FaUser } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

export const LoginPage = () => {
  const navigate = useNavigate();
  const { pushNotification } = useNotificationStore((state) => state);
  const { register, handleSubmit } = useForm<AuthLogin>({
    resolver: yupResolver<AuthLogin>(authLoginSchema),
  });

  const { mutate, isPending } = useMutation({
    mutationFn: AuthService.login,
    onSuccess: (response) => {
      if (response.success) {
        navigate(response.data!.redirect);
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  return (
    <div className="tw-min-h-[calc(100vh-655px)]">
      <div className="card card-style">
        <div className="content mt-2 mb-0">
          <form
            autoComplete="off"
            method="post"
            onSubmit={handleSubmit((data) => mutate(data))}
          >
            <div className="input-style no-borders has-icon validate-field mb-4">
              <i className="lh-1">
                <FaUser />
              </i>
              <input
                type="text"
                className="form-control"
                placeholder="Username"
                {...register("username")}
              />
              <label htmlFor="form1a" className="color-blue-dark font-10 mt-1">
                Username
              </label>
              <i className="disabled invalid color-red-dark">
                <FaTimes />
              </i>
              <i className="disabled valid color-green-dark">
                <FaCheck />
              </i>
              <em>(*wajib)</em>
            </div>

            <div className="input-style no-borders has-icon validate-field mb-4">
              <i className="lh-1">
                <FaLock />
              </i>
              <input
                type="password"
                className="form-control validate-password"
                placeholder="Password"
                {...register("password")}
              />
              <label htmlFor="form3a" className="color-blue-dark font-10 mt-1">
                Password
              </label>
              <i className="disabled invalid color-red-dark">
                <FaTimes />
              </i>
              <i className="disabled valid color-green-dark">
                <FaCheck />
              </i>
              <em>(*wajib)</em>
            </div>

            <button
              type="submit"
              className="btn btn-m mt-4 mb-3 btn-full tw-w-full bg-green-dark rounded-sm text-uppercase font-900"
            >
              {isPending ? <Spiner /> : "Login"}
            </button>
          </form>

          <div className="text-center">
            <Link to={linkPage.AUTH_FORGOT_PASSWORD}>Lupa Password?</Link>
          </div>

          <div className="divider mt-4 mb-3"></div>

          <p className="text-center tw-text-sm tw-font-light tw-text-gray-500 mb-4">
            Silahkan hubungi{" "}
            <span className="tw-font-medium tw-text-primary-700">Admin</span>{" "}
            jika terjadi masalah <br /> terhadap aplikasi
          </p>
        </div>
      </div>
    </div>
  );
};
