import { BaseLayout } from "@components/generals/base_layout";
import { Footer } from "@components/generals/footer";
import { Header } from "@components/generals/header";
import { AndroidDataEventMap } from "@declarations/event";
import { AccountService } from "@services/account.service";
import { useBiometricStore } from "@stores/biometric.store";
import { useInboxStore } from "@stores/inbox.store";
import { useThemeStore } from "@stores/theme.store";
import { androidSetupBiometric, androidTokenFirebase } from "@utils/android";
import { linkPage } from "@utils/router";
import { useCallback, useEffect } from "react";
import { FaBell } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";

export const HomePage = () => {
  const { updateStatusBiometric } = useBiometricStore((state) => state);
  const { updateUnreadInbox } = useInboxStore((state) => state);
  const { footer, setBottomNavigation, setAppBar } = useThemeStore(
    (state) => state
  );

  const onUnreadInboxListener = useCallback(
    (ev: MessageEvent<AndroidDataEventMap["on-unread-inbox"]>) => {
      updateUnreadInbox(ev.data);
    },
    [updateUnreadInbox]
  );

  const onIsAvailableBiometricListener = useCallback(
    (ev: MessageEvent<AndroidDataEventMap["on-is-available-biometric"]>) => {
      localStorage.setItem("is-biometric", `${ev.data}`);
      const skip = localStorage.getItem("skip-biometric");
      if (skip !== "true") {
        localStorage.setItem("skip-biometric", "true");
        androidSetupBiometric("register");
      }
    },
    []
  );

  const onSetupBiometric = useCallback(
    (ev: MessageEvent<AndroidDataEventMap["on-setup-biometric"]>) => {
      localStorage.setItem("status-biometric", ev.data);
      updateStatusBiometric(ev.data === "register");
    },
    [updateStatusBiometric]
  );

  const onTokenFirebaseListener = useCallback(
    (ev: MessageEvent<AndroidDataEventMap["on-token-firebase"]>) => {
      AccountService.updateTokenFirebase(ev.data);
    },
    []
  );

  useEffect(() => {
    setAppBar({
      show: true,
      leading: "home",
      actions: [
        <Link
          key={"inbox"}
          to={linkPage.HOME_INBOX}
          replace={true}
          className="header-icon header-icon-2 show-on-theme-light"
        >
          <FaBell size={16} />
        </Link>,
      ],
    });
    setBottomNavigation("home");
  }, [setBottomNavigation, setAppBar]);

  useEffect(() => {
    window.addEventListener("on-unread-inbox", onUnreadInboxListener);
    window.addEventListener(
      "on-is-available-biometric",
      onIsAvailableBiometricListener
    );
    window.addEventListener("on-setup-biometric", onSetupBiometric);

    return () => {
      window.removeEventListener("on-unread-inbox", onUnreadInboxListener);
      window.removeEventListener(
        "on-is-available-biometric",
        onIsAvailableBiometricListener
      );
      window.removeEventListener("on-setup-biometric", onSetupBiometric);
    };
  }, [onUnreadInboxListener, onIsAvailableBiometricListener, onSetupBiometric]);

  useEffect(() => {
    const updateTokenFirebase = setTimeout(() => androidTokenFirebase(), 1000);
    window.addEventListener("on-token-firebase", onTokenFirebaseListener);

    return () => {
      window.removeEventListener("on-token-firebase", onTokenFirebaseListener);
      clearTimeout(updateTokenFirebase);
    };
  }, [onTokenFirebaseListener]);

  return (
    <BaseLayout>
      <Header />

      <Outlet />

      {footer && <Footer />}
    </BaseLayout>
  );
};
