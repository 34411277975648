import { create } from "zustand";

interface BiometricStore {
  isActiveBiometric: boolean;
  updateStatusBiometric(unread: boolean): void;
}

export const useBiometricStore = create<BiometricStore>((set) => ({
  isActiveBiometric: false,
  updateStatusBiometric: (unread = false) => {
    set({ isActiveBiometric: unread });
  },
}));
