import { create } from "zustand";

interface InboxStore {
  unreadInbox: boolean;
  updateUnreadInbox(unread: boolean): void;
}

export const useInboxStore = create<InboxStore>((set) => ({
  unreadInbox: false,
  updateUnreadInbox: (unread = false) => {
    set({ unreadInbox: unread });
  },
}));
