import { useThemeStore } from "@stores/theme.store";
import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const StudentHealthRecordDetailPage = () => {
  const { state } = useLocation();
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Detail Catatan Kesehatan",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Detail Catatan Kesehatan", leading: "back" });
  }, [setFooter, setHeader, setAppBar]);

  return (
    <div className="card card-style">
      <div className="content d-flex flex-column mb-0">
        <div className="color-highlight">Tanggal</div>
        <div className="color-theme mb-3">
          {moment(state.tanggal ?? Date.now()).format(
            "dddd, DD MMMM YYYY | HH:mm"
          )}
        </div>
        <div className="color-highlight">Tingg Badan</div>
        <div className="color-theme mb-3">{state.tinggi_badan ?? "-"}</div>
        <div className="color-highlight">Berat Badan</div>
        <div className="color-theme mb-3">{state.berat_badan ?? "-"}</div>
        <div className="color-highlight">Kategori</div>
        <div className="color-theme mb-3">{state.kategori ?? "-"}</div>
        <div className="color-highlight">Catatan</div>
        <div className="color-theme mb-3">{state.catatan ?? "-"}</div>
      </div>
    </div>
  );
};
