import { PermitRequest } from "@components/resolvers/permit.resolvers";
import { BaseService } from "./base.service";
import { BaseResponse } from "./dtos/base.dto";
import { PermitResponse, PermitSettingResponse } from "./dtos/permit.dto";

interface PermitServiceInterface {
  queries: {
    GET_PERMIT_DATA: string;
    GET_PERMIT_SETTING: string;
  };
  request(payload: PermitRequest): Promise<BaseResponse<boolean>>;
  data(limit: string): Promise<PermitResponse>;
  setting(): Promise<PermitSettingResponse>;
}
export const PermitService: PermitServiceInterface = {
  queries: {
    GET_PERMIT_DATA: "GET_PERMIT_DATA",
    GET_PERMIT_SETTING: "GET_PERMIT_SETTING",
  },
  request: async (payload: PermitRequest) => {
    try {
      const request = await BaseService.request().post<BaseResponse<boolean>>(
        "/permit/request",
        payload
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  data: async (limit: string) => {
    try {
      const request = await BaseService.request().get<PermitResponse>(
        "/permit/data",
        { params: { limit } }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  setting: async () => {
    try {
      const request = await BaseService.request().get<PermitSettingResponse>(
        "/permit/setting"
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
};
