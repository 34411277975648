import { useThemeStore } from "@stores/theme.store";
import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const StudentHealthDetailPage = () => {
  const { state } = useLocation();
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Detail Kesehatan Santri",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Detail Kesehatan Santri", leading: "back" });
  }, [setFooter, setHeader, setAppBar]);

  return (
    <div className="card card-style">
      <div className="content d-flex flex-column mb-0">
        <div className="color-highlight">Tanggal Keluhan</div>
        <div className="color-theme mb-3">{moment(state.tanggal ?? Date.now()).format('dddd, DD MMMM YYYY | HH:mm')}</div>
        <div className="color-highlight">Keluhan</div>
        <div className="color-theme mb-3">{state.keluhan ?? '-'}</div>
        <div className="color-highlight">Pengobatan</div>
        <div className="color-theme mb-3">{state.obat ?? '-'}</div>
        <div className="color-highlight">Catatan</div>
        <div className="color-theme mb-3">{state.catatan ?? '-'}</div>
      </div>
    </div>
  );
};
